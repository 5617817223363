<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <h2 v-if="number">Arbeitsnachweis Nr. {{ number }}</h2>
            <h2 v-else>
              Arbeitsnachweis Nr.
              <span style="font-size: 12px; font-weight: 400; margin-left: 10px">(Nummer wird nach dem Speichern
                generiert)</span>
            </h2>
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-alert v-if="ended" type="warning">Dieser Arbeitsnachweis wurde bereits abgeschlossen und kann nicht
              mehr verändert werden.</v-alert>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="8" xl="6" v-if="(assignmentid && assignmentid) != 'null' &&
              (mode === 'execute' || mode === 'view' || hasInfo)
              ">
            <v-card class="mx-auto" flat>
              <v-card-title>Auftrag</v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>
                            <b>Auftragsnummer</b>
                          </td>
                          <td>{{ assignment.assignmentnumber }}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Kundenname</b>
                          </td>
                          <td>
                            {{
              assignment.customername ||
              customProps.customername
            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Standortbezeichnung</b>
                          </td>
                          <td>{{ assignment.standortname }}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Adresse</b>
                          </td>
                          <td v-if="assignment.street">
                            {{ assignment.street }} {{ assignment.houseno }},
                            {{ assignment.zip }} {{ assignment.city }}
                          </td>
                          <td v-else>{{ customProps.address }}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Kommentar</b>
                          </td>
                          <td>{{ assignment.comment }}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Mitarbeiter</b>
                          </td>
                          <td>{{ assignment.usernames }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="8" xl="6" v-else>
            <v-card class="mx-auto" flat>
              <v-card-title>Auftrag</v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-form ref="newform">
                    <v-select item-text="assignmentnumber" :loading="isLoading" clearable :items="assignments"
                      @change="selectAuftrag" return-object label="Auftrag auswählen" v-model="selectedAssigment">
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.assignmentnumber"></v-list-item-title>
                          <v-list-item-subtitle v-if="item.customername"
                            v-text="item.customername"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <div v-if="!selectedAssigment">
                      <v-text-field v-model="info.customername" label="Kundenname*" required
                        :rules="[rules.required]"></v-text-field>
                      <v-text-field v-model="info.address" label="Adresse*" required
                        :rules="[rules.required]"></v-text-field>
                      <v-select v-model="plids" :items="leiters" item-value="id" item-text="name" label="Projektleiter*"
                        multiple required :rules="[rules.requiredarray]"></v-select>
                    </div>
                    <v-textarea label="Kommentar" outlined v-model="comment"></v-textarea>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <!--  <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-radio-group v-model="type" row>
                        <v-radio label="Service" value="service"></v-radio>
                        <v-radio label="Wartung" value="wartung"></v-radio>
                        <v-radio label="Montage" value="montage"></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field v-model="subject" label="Betreff"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>-->
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <div style="padding: 30px; color: rgba(0, 0, 0, 0.87)" class="v-toolbar__title">
                  Informationen
                </div>
                <v-container grid-list-md>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="subject" label="Betreff*" required :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-radio-group v-model="type" row required :rules="[rules.required]">
                    <v-radio label="Service" value="Service"></v-radio>
                    <v-radio label="Wartung" value="Wartung"></v-radio>
                    <v-radio label="Montage" value="Montage"></v-radio>
                  </v-radio-group>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-data-table :headers="headers" sort-by="date" :items="hours" :items-per-page="-1"
                        hide-default-footer>
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Gebuchte Stunden</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="showHours = true" fab color="success">
                              <i class="material-icons">add</i>
                            </v-btn>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.date="{ item }">{{
              $formatDateWithDay(item.date)
            }}</template>
                        <!--   <template v-slot:item.pauschal="{ item }">
                          <span v-if="item.pauschal" style="font-weight:bold">Ja</span>
                          <span v-else>Nein</span>
                        </template>-->
                        <!--  <template v-slot:item.hours="{ item }">
                          <span v-if="item.pauschal">Pauschalpreis</span>
                          <span v-else>{{item.hours}}</span>
                        </template>
                        <template v-slot:item.nighthours="{ item }">
                          <span v-if="item.pauschal">Pauschalpreis</span>
                          <span v-else>{{item.nighthours}}</span>
                        </template>
                        <template v-slot:item.overhours="{ item }">
                          <span v-if="item.pauschal">Pauschalpreis</span>
                          <span v-else>{{item.overhours}}</span>
                        </template>-->
                        <template v-slot:item.product="{ item }">
                          <div v-if="item.pauschal">Pauschalpreis</div>
                          <div v-else>{{ item.product }}</div>
                        </template>
                        <template v-slot:item.category="{ item }">
                          <div v-if="item.pauschal">Pauschalpreis</div>
                          <div v-else>{{ item.category }}</div>
                        </template>
                        <template v-slot:item.count="{ item }">
                          <div v-if="item.pauschal">Pauschalpreis</div>
                          <div v-else>{{ item.count }}</div>
                        </template>
                        <template v-slot:item.price="{ item }">
                          <div v-if="item.pauschal">Pauschalpreis</div>
                          <div v-else>{{ $formatCurrency(item.price) }}</div>
                        </template>
                        <template v-slot:item.sumprice="{ item }">
                          <div v-if="item.pauschal">Pauschalpreis</div>
                          <div v-else>{{ $formatCurrency(item.sumprice) }}</div>
                        </template>

                        <template v-slot:item.action="{ item }">
                          <div class="text-center inline-flex">
                            <v-btn @click="editHours(item)" fab small color="primary" class="btn-icon btn-cyan">
                              <v-icon>edit</v-icon>
                            </v-btn>

                            <v-btn @click="deleteItem(item, hours)" fab small color="error" class="btn-icon btn-cyan">
                              <i class="material-icons">delete</i>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <v-flex sm12 md6 lg4 xl3 offset-md6 offset-lg8 offset-xl9 v-if="showPricesToRole">
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <b>Summe netto</b>
                              </td>
                              <td>{{ $formatCurrency(hourNetPrice) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>MwSt. {{ companyconfig.mwst }}%</b>
                              </td>
                              <td>
                                {{
              $formatCurrency(
                (hourNetPrice * companyconfig.mwst) / 100
              )
            }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Gesamtbetrag</b>
                              </td>
                              <td>
                                {{
                $formatCurrency(
                  (hourNetPrice * companyconfig.mwst) / 100 +
                  hourNetPrice
                )
              }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <!-- <v-flex xs12 sm12 md12 class="text-right pt-5 bold">
                      <div>
                        Gebuchte Stunden:
                        <b>{{bookedhours}}</b>
                      </div>
                      <div>
                        Gebuchte Über-Stunden:
                        <b>{{bookedoverhours}}</b>
                      </div>
                      <div>
                        Gebuchte Nacht-Stunden:
                        <b>{{bookednighthours}}</b>
                      </div>
                    </v-flex>-->
                    <small>Regelarbeitzeiten: 7.00 - 15.15 Uhr. Nacht: ab 20
                      Uhr</small>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-data-table :headers="headersmaterial" :items-per-page="-1" :items="items" sort-by="id"
                        hide-default-footer>
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Material Verwendung</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="showMaterial = true" fab color="success">
                              <i class="material-icons">add</i>
                            </v-btn>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.price="{ item }">
                          <div>{{ $formatCurrency(item.price) }}</div>
                        </template>
                        <template v-slot:item.sumprice="{ item }">
                          <div>{{ $formatCurrency(item.sumprice) }}</div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <div class="text-center inline-flex">
                            <!--   <v-btn @click="editMaterial(item)" fab small color="primary" class="btn-icon btn-cyan">
                              <v-icon>edit</v-icon>
                            </v-btn>
 -->
                            <v-btn @click="deleteItem(item, items, 'mat')" fab small color="error"
                              class="btn-icon btn-cyan">
                              <i class="material-icons">delete</i>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                      <small>Sämtliches Material bleibt bis zur vollständigen
                        Bezahlung Eigentum der {{ companyconfig.name }}</small>
                    </v-flex>
                    <v-flex sm12 md6 lg4 xl3 offset-md6 offset-lg8 offset-xl9 v-if="showPricesToRole">
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <b>Summe netto</b>
                              </td>
                              <td>{{ $formatCurrency(materialNetPrice) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>MwSt. {{ companyconfig.mwst }}%</b>
                              </td>
                              <td>
                                {{
              $formatCurrency(
                (materialNetPrice * companyconfig.mwst) /
                100
              )
            }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Gesamtbetrag</b>
                              </td>
                              <td>
                                {{
                $formatCurrency(
                  (materialNetPrice * companyconfig.mwst) /
                  100 +
                  materialNetPrice
                )
              }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <div style="padding: 30px; color: rgba(0, 0, 0, 0.87)" class="v-toolbar__title">
                  Durchgeführte Arbeiten
                </div>

                <v-textarea outlined v-model="work"></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md4 lg3>
                      Arbeiten abgeschlossen
                      <v-radio-group v-model="finalized" row>
                        <v-radio label="Ja" value="1"></v-radio>
                        <v-radio label="Nein" value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg3>
                      Endprüfung durchgeführt
                      <v-radio-group v-model="lastcheck" row>
                        <v-radio label="Ja" value="1"></v-radio>
                        <v-radio label="Nein" value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <div style="padding: 30px; color: rgba(0, 0, 0, 0.87)" class="v-toolbar__title">
                  Dokumente
                </div>
                <div class="card-body" v-if="images && images.length">
                  <div class="img-thumb" v-for="(image, index) in images" :key="image">
                    <v-img :src="getImage(image)" contain></v-img>
                    <v-btn class="mt-5" @click="deleteImage(index)" color="error">Bild löschen</v-btn>
                  </div>
                </div>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-file-input v-model="uploadimages" color="primary accent-4" counter label="Bilder" accept="image/*"
                    multiple placeholder="Hier klicken um Bilder hochzuladen" prepend-icon="mdi-paperclip" outlined
                    :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="primary accent-4" dark label small>{{ text }}</v-chip>

                      <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">+{{ files.length - 2
                        }} File(s)</span>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3">
                  <v-btn @click="uploadImages()" color="primary" :disabled="uploadimages.length === 0">Bilder
                    Hochladen</v-btn>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="10" md="6">
            <div class="card-title">Unterschrift Service-Techniker</div>
            <vueSignature h="240px" w="450px" ref="signature"></vueSignature>
            <v-btn color="error" text @click="clear(1)">Löschen</v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="10" md="6">
            <div class="card-title">Unterschrift Kunde</div>
            <vueSignature h="240px" w="450px" ref="signatureClient"></vueSignature>
            <v-btn color="error" text @click="clear(2)">Löschen</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="success" v-if="mode === 'execute' || mode === 'new'" :disabled="!saved || ended || !assignmentid || assignmentid == 'null'
              " @click="finalizeArbeitsnachweis()">Arbeitsnachweis abschließen</v-btn>
            <v-btn color="primary" class="float-right" :disabled="ended" @click="saveData()">Arbeitsnachweis
              speichern</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showHours" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">Stunden buchen</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-form ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="newHour.date" label="Datum*" prepend-icon="event" readonly v-on="on"
                        required :rules="[rules.required]"></v-text-field>
                    </template>
                    <v-date-picker v-model="newHour.date" no-title first-day-of-week="1" scrollable
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <!-- <v-text-field
                    label="Mitarbeitername*"
                    v-model="newHour.name"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>-->
                  <v-combobox item-text="name" item-value="name" :items="users" :return-object="false"
                    v-model="newHour.name" label="Mitarbeitername*" required
                    :rules="[rules.required, rules.maxlength]"></v-combobox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-checkbox v-model="newHour.pauschal" label="Pauschalabrechnung?"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="!newHour.pauschal">
                  <v-text-field v-model="newHour.count" label="Stunden*" required type="number"
                    :rules="[rules.required]"></v-text-field>
                  <!-- <v-text-field
                    v-model="newHour.hours"
                    type="number"
                    label="Stunden*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>-->
                </v-flex>
                <v-flex xs12 sm8 md8 v-if="!newHour.pauschal">
                  <v-autocomplete v-model="newHour.object" item-value="id" return-object :items="pricelist"
                    :filter="filterPrice" persistent-hint label="Bezeichnung*">
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <div>
                          <b>{{ item.name }}</b>
                          <span v-if="showPricesToRole">({{ $formatCurrency(item.price) }})</span>
                        </div>
                        <div class="grey--text">
                          Produkt ID: {{ item.productid }}
                        </div>
                        <div class="grey--text">
                          Kategorie: {{ item.category }}
                        </div>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">{{
              item.name
            }}</template>
                  </v-autocomplete>
                </v-flex>
                <!--  <v-flex xs12 sm4 md4 v-if="!newHour.pauschal">
                  <v-text-field
                    v-model="newHour.overhours"
                    type="number"
                    label="Über-Stunden"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="!newHour.pauschal">
                  <v-text-field
                    v-model="newHour.nighthours"
                    type="number"
                    label="Nacht-Stunden"
                    required
                  ></v-text-field>
                </v-flex>-->
                <!--  <v-flex xs12 sm12 md12>
                  <v-textarea outlined v-model="newHour.work" label="Ausgeführte Arbeiten"></v-textarea>
                </v-flex>-->
                <small>Regelarbeitzeiten: 7.00 - 15.15 Uhr. Nacht: ab 20 Uhr</small>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveHours()">Speichern</v-btn>
          <v-btn color="error" text @click="
              showHours = false;
            clearHour();
            ">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMaterial" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">Material buchen</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-form ref="form2">
              <v-layout wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field v-model="newMaterial.count" label="Menge + Einheit*" required
                    :rules="[rules.required]"></v-text-field>
                </v-flex>
                <v-flex xs12 sm9 md9 v-if="showMaterialSelection">
                  <v-combobox :items="materials" v-model="newMaterial.object" label="Bezeichnung*" required
                    ref="matinput" :rules="[rules.required]">
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <div>
                          <b>{{ item.name }}</b>
                          <span v-if="showPricesToRole">({{ $formatCurrency(item.price) }})</span>
                        </div>
                        <div class="grey--text">
                          Material ID: {{ item.materialid }}
                        </div>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span v-if="item && item.name">{{ item.name }}</span>
                      <span v-else>{{ item }}</span>
                    </template>
                  </v-combobox>
                  <!--  <v-autocomplete
                    v-model="newMaterial.object"
                    :items="materials"
                    :filter="filterMaterials"
                    persistent-hint
                    label="Bezeichnung*"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <div>
                          <b>{{ item.name }}</b>
                          <span v-if="showPricesToRole"
                            >({{ $formatCurrency(item.price) }})</span
                          >
                        </div>
                        <div class="grey--text">
                          Material ID: {{ item.materialid }}
                        </div>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">{{
                      item.name
                    }}</template>
                  </v-autocomplete> -->
                </v-flex>
                <v-flex xs12 sm9 md9 v-else>
                  <v-text-field v-model="newMaterial.name" label="Bezeichnung*" required
                    :rules="[rules.required]"></v-text-field>
                </v-flex>
                <!--     <v-flex xs12 sm2 md2>
                  <v-text-field v-model="newMaterial.price" type="number" label="Preis"></v-text-field>
                </v-flex>-->
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveMaterial()">Speichern</v-btn>
          <v-btn color="error" text @click="
              showMaterial = false;
            clearMaterial();
            ">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import vueSignature from "vue-signature";

export default {
  components: {
    vueSignature,
  },
  data() {
    return {
      ended: false,
      saved: false,
      selectedAssigment: null,
      showMaterialSelection: false,
      showHourSelection: false,
      showPricesToRole: false,
      images: [],
      uploadimages: [],
      info: {},
      assignment: {},
      assignments: [],
      hasInfo: false,
      materialNetPrice: 0,
      hourNetPrice: 0,
      companyconfig: {},
      number: "",
      savedId: null,
      finalized: 2,
      lastcheck: 2,
      materials: [],
      pricelist: [],
      users: [],
      comment: "",
      newAssignment: {
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        comment: "",
      },
      type: "",
      subject: "",
      plids: [],
      leiters: [],
      pl: "",
      newHour: {
        name: "",
        date: "",
        hours: 0,
        overhours: 0,
        nighthours: 0,
        work: "",
      },
      customProps: {},
      newMaterial: {
        name: "",
        count: "",
        price: 0,
      },
      menu: false,
      menu2: false,
      showHours: false,
      showMaterial: false,
      hours: [],
      items: [],
      subject: "",
      type: "",
      work: "",
      items: [],
      isLoading: false,
      model: null,
      search: null,
      headersmaterial: [
        {
          text: "Menge",
          align: "left",
          sortable: false,
          value: "count",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      headers: [
        {
          text: "Datum",
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: "Mitarbeitername",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Stunden",
          align: "center",
          sortable: false,
          value: "count",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "product",
        },
        {
          text: "Kategorie",
          align: "center",
          sortable: false,
          value: "category",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
        /* {
          text: "Über-Stunden",
          align: "center",
          sortable: false,
          value: "overhours"
        },
        {
          text: "Nacht-Stunden",
          align: "center",
          sortable: false,
          value: "nighthours"
        }, */
        /*  {
          text: "Pauschalabrechnung",
          align: "center",
          sortable: false,
          value: "pauschal"
        }, */
        /*  {
          text: "Ausgeführte Arbeiten",
          align: "left",
          sortable: false,
          value: "work"
        }, */
        /*  {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action"
        } */
      ],
      rules: {
        required: (value) => !!value || "Erforderlich.",
        requiredarray: (value) => !!value.length || "Erforderlich.",
        maxlength: (v) => v.length < 100 || "Maximal 100 Zeichen",
      },
    };
  },
  mounted: function () {
    /*  const companyconfig = (this.companyconfig =
      this.$store.getters.companyconfig);
    this.showPricesToRole =
      companyconfig &&
      companyconfig.showPricesToRoles &&
      companyconfig.showPricesToRoles.includes(this.role);

    this.pricelist = companyconfig && companyconfig.pricelist;
    if (this.pricelist && this.pricelist.length) {
      this.showHourSelection = true;
    }
    this.materiallist = companyconfig && companyconfig.materiallist; */

    this.getConfig();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    assignmentid() {
      return this.$route.params.assignmentid;
    },
    mode() {
      return this.$route.params.mode;
    },
    role() {
      return this.$store.getters.user.role;
    },
    /*  bookedhours() {
      let hours = 0;
      this.hours.forEach(h => {
        hours += parseFloat(h.hours);
      });
      return hours;
    },
    bookednighthours() {
      let hours = 0;
      this.hours.forEach(h => {
        hours += parseFloat(h.nighthours);
      });
      return hours;
    },
    bookedoverhours() {
      let hours = 0;
      this.hours.forEach(h => {
        hours += parseFloat(h.overhours);
      });
      return hours;
    } */
  },
  watch: {
    search(val) {
      if (!val) {
        this.items = [];
        return;
      }
      this.isLoading = true;
      const self = this;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "getAssignmentsSearch.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            self.items = JSON.parse(JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    getImage(img) {
      if (img) {
        return this.$http.defaults.serverURL + "/uploads/" + img;
      }
    },
    getData() {
      if (this.mode !== "new" && this.id) {
        this.saved = true;
        this.getArbeitsnachweis();
      }
      if (
        this.assignmentid &&
        this.assignmentid !== "-1" &&
        this.assignmentid != "null"
      ) {
        this.getAuftragsInfo();
      }
      if (
        !this.assignmentid ||
        this.assignmentid == "-1" ||
        this.assignmentid == "null"
      ) {
        this.getOpenAssignments();
      }
    },
    /*    getProjektleiter() {
      this.isLoading = true;
      this.$http({
        method: "post",
        url: "getProjektleiter.php",
      })
        .then((response) => {
          this.isLoading = false;
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.leiters = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    }, */
    getOpenAssignments() {
      this.isLoading = true;
      this.$http({
        method: "post",
        url: "getOpenAssignments.php",
      })
        .then((response) => {
          this.isLoading = false;
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.assignments = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
    getConfig() {
      let formData = new FormData();
      formData.append("users", 1);
      this.$http({
        method: "post",
        url: "getCompanyConfig.php",
        data: formData,
      })
        /*  this.$http
        .get("/structure/structure") */
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data && response.data.data) {
            const config = (this.companyconfig = response.data.data);
            if (config.pricelist) {
              this.pricelist = JSON.parse(config.pricelist);
            }
            if (config.materiallist) {
              this.materials = JSON.parse(config.materiallist);
              this.showMaterialSelection = true;
            }
            if (config.showPricesToRoles) {
              const showPricesToRoles = JSON.parse(config.showPricesToRoles);
              this.showPricesToRole =
                showPricesToRoles && showPricesToRoles.includes(this.role);
            }

            this.getData();
          }
          if (response.status === 200 && response.data && response.data.users && response.data.users.length) {
            const users = response.data.users;
            this.users = users;
            const pl = users.filter(u => {
              return u.role == "pl";
            })
            this.leiters = pl;

          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
    saveHours() {
      if (this.$refs.form.validate()) {
        const newHour = JSON.parse(JSON.stringify(this.newHour));
        /* newHour.hours = parseFloat(newHour.hours);
        newHour.nighthours = parseFloat(newHour.nighthours);
        newHour.overhours = parseFloat(newHour.overhours); */
        if (newHour.object) {
          newHour.product = newHour.object.name;
          newHour.productid = newHour.object.productid;
          newHour.itemid = newHour.object.id;
          newHour.price = newHour.object.price;
          newHour.category = newHour.object.category;
          newHour.sumprice = parseFloat(newHour.count) * newHour.object.price;
          delete newHour.object;
        }
        if (newHour.id) {
          const id = this.newHour.id;
          this.hours.forEach((hour, i) => {
            if (hour.id === id) {
              this.hours.splice(i, 1);
            }
          });
        } else {
          newHour.id = new Date().getTime();
        }
        this.hours.push(newHour);
        this.calcHours();
        this.clearHour();
        this.showHours = false;
      }
    },
    saveMaterial() {
      const input = this.$refs["matinput"];
      input.blur();
      setTimeout(() => {
        if (this.$refs.form2.validate()) {
          const newMaterial = JSON.parse(JSON.stringify(this.newMaterial));
          if (newMaterial.id) {
            const id = this.newMaterial.id;
            this.items.forEach((mat, i) => {
              if (mat.id === id) {
                this.items.splice(i, 1);
              }
            });
          } else {
            newMaterial.id = new Date().getTime();
          }
          if (newMaterial.object && this.showMaterialSelection) {
            if (typeof newMaterial.object === "object") {
              newMaterial.name = newMaterial.object.name;
              newMaterial.materialid = newMaterial.object.materialid;
              newMaterial.price = newMaterial.object.price;
              newMaterial.sumprice =
                parseFloat(newMaterial.count) * newMaterial.object.price;
            } else {
              newMaterial.name = newMaterial.object;
            }
            delete newMaterial.object;
          }
          this.items.push(newMaterial);
          this.calcMaterials();
          this.clearMaterial();
          this.showMaterial = false;

        }
      }, 300)
    },
    calcMaterials() {
      let sumprice = 0;
      this.items.forEach((item) => {
        sumprice += item.sumprice;
      });
      this.materialNetPrice = sumprice;
    },
    calcHours() {
      let sumprice = 0;
      this.hours.forEach((item) => {
        sumprice += item.sumprice;
      });
      this.hourNetPrice = sumprice;
    },
    filterMaterials(item, queryText, itemText) {
      const queryString = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(queryString) ||
        item.description.toLowerCase().includes(queryString) ||
        item.materialid.toLowerCase().includes(queryString)
      );
    },
    filterPrice(item, queryText, itemText) {
      const queryString = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(queryString) ||
        item.category.toLowerCase().includes(queryString) ||
        item.productid.toLowerCase().includes(queryString)
      );
    },
    selectAuftrag() {
      const model = this.selectedAssigment;
      if (model && model.customerid) {
        let formData = new FormData();
        formData.append("id", model.customerid);
        let self = this;
        this.$http({
          method: "post",
          url: "getCustomerConfig.php",
          data: formData,
        }).then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = self.$http;
            self.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                self.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            self.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            if (
              response.data.data.pricelist &&
              response.data.data.pricelist !== ""
            ) {
              self.pricelist = JSON.parse(response.data.data.pricelist);
              this.showHourSelection = true;
              if (this.showPricesToRole) {
                this.headers.splice(5, 0, {
                  text: "Einzelpreis",
                  align: "left",
                  sortable: false,
                  value: "price",
                });

                this.headers.splice(6, 0, {
                  text: "Gesamtpreis",
                  align: "left",
                  sortable: false,
                  value: "sumprice",
                });
              }
            }
            if (
              response.data.data.materiallist &&
              response.data.data.materiallist !== ""
            ) {
              self.materials = JSON.parse(response.data.data.materiallist);
              this.showMaterialSelection = true;
              this.headersmaterial.splice(2, 0, {
                text: "Material ID",
                align: "left",
                value: "materialid",
              });
              if (this.showPricesToRole) {
                this.headersmaterial.splice(3, 0, {
                  text: "Einzelpreis",
                  align: "left",
                  sortable: false,
                  value: "price",
                });
                this.headersmaterial.splice(4, 0, {
                  text: "Gesamtpreis",
                  align: "left",
                  sortable: false,
                  value: "sumprice",
                });
              }
            }
          }
        });
      }
    },
    clearHour() {
      this.newHour = {
        name: "",
        date: "",
        hours: 0,
        overhours: 0,
        nighthours: 0,
        pauschal: false,
        work: "",
      };
    },
    editHours(item) {
      const newHour = JSON.parse(JSON.stringify(item));
      if (newHour.itemid) {
        newHour.object = {
          id: newHour.itemid
        }
      }
      this.newHour = newHour;
      this.showHours = true;
    },
    editMaterial(item) {
      const newMat = JSON.parse(JSON.stringify(item));
      if (newMat.materialid) {
        newMat.object = {
          name: newMat.name,
          materialid: newMat.materialid,
          price: newMat.price,
          sumprice: parseFloat(newMat.count) * newMat.price
        }
      } else {
        newMat.object = newMat.name;
      }

      this.newMaterial = newMat;
      this.showMaterial = true;
    },
    clearMaterial() {
      this.newMaterial = {
        name: "",
        count: "",
        price: 0,
      };
    },
    deleteItem(item, array, type) {
      const index = array.indexOf(item);
      array.splice(index, 1);
      if (type === "mat") {
        this.calcMaterials();
      }
    },
    clear(code) {
      if (code === 1 && this.$refs.signature) {
        this.$refs.signature.clear();
      }
      if (code === 2 && this.$refs.signatureClient) {
        this.$refs.signatureClient.clear();
      }
    },
    deleteImage(index) {
      if (this.images && this.images.length) {
        this.images.splice(index, 1);
      }
    },
    uploadImages() {
      const self = this;
      let formData = new FormData();
      const images = this.uploadimages;
      for (let i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("images[" + i + "]", file);
      }
      this.$http({
        method: "post",
        url: "uploadImage.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success &&
            response.data.filenames
          ) {
            this.uploadimages = [];
            this.images = this.images.concat(response.data.filenames);
            const msg = "Bilder wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAuftragsInfo() {
      let formData = new FormData();
      formData.append("assignmentid", this.assignmentid);
      let self = this;
      this.$http({
        method: "post",
        url: "getAssignmentForArbeitsnachweis.php",
        //url: "getAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = self.$http;
            self.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                self.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            self.$router.push("/");
          }
          if (
            response.data &&
            response.data.success &&
            response.data.assignment
          ) {
            self.assignment = response.data.assignment;
            self.hasInfo = true;
          }
          if (
            response.data &&
            response.data.success &&
            response.data.customerconfig
          ) {
            if (
              response.data.customerconfig.pricelist &&
              response.data.customerconfig.pricelist !== ""
            ) {
              self.pricelist = JSON.parse(
                response.data.customerconfig.pricelist
              );
              this.showHourSelection = true;
              if (this.showPricesToRole) {
                this.headers.splice(5, 0, {
                  text: "Einzelpreis",
                  align: "left",
                  sortable: false,
                  value: "price",
                });

                this.headers.splice(6, 0, {
                  text: "Gesamtpreis",
                  align: "left",
                  sortable: false,
                  value: "sumprice",
                });
              }
            }
            /*  if (response.data.users) {
               self.users = response.data.users;
             } */
            if (
              response.data.customerconfig.materiallist &&
              response.data.customerconfig.materiallist !== ""
            ) {
              self.materials = JSON.parse(
                response.data.customerconfig.materiallist
              );
              this.showMaterialSelection = true;
              this.headersmaterial.splice(2, 0, {
                text: "Material ID",
                align: "left",
                value: "materialid",
              });
              if (this.showPricesToRole) {
                this.headersmaterial.splice(3, 0, {
                  text: "Einzelpreis",
                  align: "left",
                  sortable: false,
                  value: "price",
                });
                this.headersmaterial.splice(4, 0, {
                  text: "Gesamtpreis",
                  align: "left",
                  sortable: false,
                  value: "sumprice",
                });
              }
            }
          }
        })
        .catch(function (error) {
          error;
        });
    },
    getArbeitsnachweis() {
      let formData = new FormData();
      formData.append("id", this.id);
      let self = this;
      this.$http({
        method: "post",
        url: "getArbeitsnachweis.php",
        //url: "getAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = self.$http;
            self.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                self.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            self.$router.push("/");
          }
          if (response.data && response.data.success && response.data.info) {
            if (self.role === "worker" && response.data.info.status !== "0") {
              self.ended = true;
            }
            self.info = response.data.info;
            self.items = JSON.parse(response.data.info.material || "[]");
            self.calcMaterials();
            self.hours = JSON.parse(response.data.info.hours || "[]");
            self.calcHours();
            if (response.data.info.info && response.data.info.info != "" && response.data.info.info != "NULL") {
              self.info = JSON.parse(response.data.info.info);
            } else {
              self.info = {};
            }
            const plids = JSON.parse(response.data.info.plids || "[]");
            if (plids && plids.length) {
              self.plids = plids.map((id) => {
                return id.toString();
              });
            }
            if (
              response.data.info.images &&
              response.data.info.images.trim() !== ""
            ) {
              self.images = response.data.info.images.split(";");
            } else {
              self.images = [];
            }
            self.subject = response.data.info.subject;
            self.type = response.data.info.type;
            self.number = response.data.info.number;
            self.work = response.data.info.work;
            self.finalized = response.data.info.finalized;
            self.lastcheck = response.data.info.lastcheck;
            self.$refs.signature.fromDataURL(response.data.info.signature);
            self.$refs.signatureClient.fromDataURL(
              response.data.info.signatureclient
            );
            if (
              self.items.length &&
              self.hours.length &&
              !self.$refs.signature.isEmpty() &&
              !self.$refs.signatureClient.isEmpty()
            ) {
              self.saved = true;
            }
          }
        })
        .catch(function (error) {
          error;
        });
    },
    finalizeArbeitsnachweis() {
      let formData = new FormData();
      const id = this.id || this.savedId;
      if (!id) {
        alert("Es ist ein fehler aufgetretten");
        return;
      }
      formData.append("id", id);
      formData.append("status", 2);
      this.$http({
        method: "post",
        url: "changeArbeitsnachweisStatus.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            const msg =
              "Der Arbeitsnachweis wurde erfolgreich übermittelt und ist nun abgeschlossen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$router.go(-1);
          } else {
            const msg =
              "Die Arbeitsnachweis konnte nicht übermittelt werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
          const msg =
            "Die Arbeitsnachweis konnte nicht übermittelt werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    saveData() {
      /* if (this.mode === "new") {
        if (!this.newAssignment.id) {
          this.saveAssignment();
          return;
        }
        this.save(this.newAssignment.id);
      } else {
        this.save(this.id);
      } */
      if (this.mode === "new") {
        this.save(0);
      } else {
        this.save(this.id);
      }
    },
    getArbeitsnachweisNumber(assignmentid) {
      let formData = new FormData();
      formData.append("id", assignmentid);
      this.$http({
        method: "post",
        url: "getAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (
            response.data &&
            response.data.success &&
            response.data.info &&
            response.data.info.number
          ) {
            this.number = response.data.info.number;
          }
        })
        .catch(function (error) { });
    },
    save(id) {
      let formData = new FormData();
      formData.append("id", id || this.savedId);
      const model = this.selectedAssigment;
      let assignmentid = null;
      if (model && model.id) {
        assignmentid = model.id;
      }
      if (
        this.assignmentid &&
        this.assignmentid !== "-1" &&
        this.assignmentid !== "null"
      ) {
        assignmentid = this.assignmentid;
      }
      formData.append("assignmentid", assignmentid);

      if (this.info && Object.keys(this.info).length > 0) {
        formData.append("info", JSON.stringify(this.info));
      }
      if (this.plids && this.plids.length > 0) {
        const ids = this.plids.map((id) => {
          return parseInt(id);
        });
        formData.append("plids", JSON.stringify(ids));
      }
      let hours = this.hours;
      hours.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      let materials = this.items;
      materials.sort((a, b) => {
        return a.id - b.id;
      });
      formData.append("hours", JSON.stringify(hours));
      formData.append("material", JSON.stringify(materials));
      let sig = "";
      let sigClient = "";
      if (this.$refs.signature && !this.$refs.signature.isEmpty()) {
        sig = this.$refs.signature.save();
      }
      if (this.$refs.signatureClient && !this.$refs.signatureClient.isEmpty()) {
        sigClient = this.$refs.signatureClient.save();
      }
      formData.append("type", this.type);
      formData.append("work", this.work);
      formData.append("subject", this.subject);
      formData.append("comment", this.comment);
      formData.append("finalized", this.finalized);
      formData.append("lastcheck", this.lastcheck);
      formData.append("signature", sig);
      formData.append("signatureclient", sigClient);
      let images = "";
      if (this.images && this.images.length) {
        images = this.images.join(";");
      }
      formData.append("images", images);
      this.$http({
        method: "post",
        url: "saveArbeitsnachweis.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            const id = response.data.id;
            this.savedId = id;
            const number = response.data.number;
            const msg = "Die Änderungen wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.saved = true;
            if (!this.number && number) {
              this.number = number;
              //    this.getArbeitsnachweisNumber(id);
            }
          } else {
            const msg =
              "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const msg =
            "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>
